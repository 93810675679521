import { createSlice } from "@reduxjs/toolkit";
// import { Cookies } from "react-cookie";
import { notify } from "../../utils/toastNotification";
import {
  login as loginAPI,
  getUserProfile,
  registerApi,
  createPassword,
  forgottenPassword as forgotPassword,
  resetPasswordApi,
  getAllPermission,
} from "../actions/auth.action";

import {
  clearStorage,
  getAuthUser,
  getToken,
  setAuthUser,
  setToken,
} from "../../utils/storage";

const initialState = {
  accessToken: getToken(),
  userProfile: getAuthUser(),
  isLoading: false,
  permissions: [],
  login: {
    isLoading: false,
  },
  register: {
    isLoading: false,
  },
  createPassword: {
    isLoading: false,
  },
  forgotPassword: {
    isLoading: false,
  },
  resetPassword: {
    isLoading: false,
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state, action) {
      state.userProfile = {};
      window.location.href = "/";
      clearStorage();
    },
  },
  extraReducers: (builder) => {
    // CLIENT LOGIN
    builder
      .addCase(
        loginAPI.fulfilled,
        (state, { payload: { token, dispatch }, ...rest }) => {
          console.log("fulfilled", state, rest);
          state.login.isLoading = false;
          setToken(token);
          // notify("Login success", { type: "success" });
        }
      )
      .addCase(loginAPI.pending, (state, action) => {
        state.login.isLoading = true;
      })
      .addCase(loginAPI.rejected, (state, { error }) => {
        console.log("rejected");
        state.login.isLoading = false;
      })

      // User PROFILE GET
      .addCase(getUserProfile.fulfilled, (state, { payload }) => {
        console.log("fulfilled", payload);
        if (payload?.myRole?.role === "Agent") {
          notify("Agents can only login on the mobile app", { type: "error" });

          // Ensure clearStorage is correctly implemented or just use localStorage.clear()
          localStorage.clear(); // This will clear everything in localStorage
          return;
        }
        setAuthUser(payload);
        // notify("Login success", { type: "success" });
        window.location.href = "/dashboard";
      })

      .addCase(getUserProfile.pending, (state, action) => {
        state.login.isLoading = true;
      })
      .addCase(getUserProfile.rejected, (state, { error }) => {
        console.log("rejected");
        state.login.isLoading = false;
      });

    // CLIENT REGISTER
    builder
      .addCase(registerApi.fulfilled, (state, { payload }) => {
        console.log("fulfilled", payload);
        state.register.isLoading = false;
        notify("Registration successful", { type: "success" });
        window.location.href = "/email_sent";
      })
      .addCase(registerApi.pending, (state, action) => {
        state.register.isLoading = true;
      })
      .addCase(registerApi.rejected, (state, { error }) => {
        console.log("rejected");
        state.register.isLoading = false;
      });

    // CLIENT CREATE PASSWORD
    builder
      .addCase(createPassword.fulfilled, (state, { payload }) => {
        console.log("fulfilled", payload);
        state.createPassword.isLoading = false;
        notify("Password created successfully", { type: "success" });
        window.location.href = "/";
      })
      .addCase(createPassword.pending, (state, action) => {
        state.createPassword.isLoading = true;
      })
      .addCase(createPassword.rejected, (state, { error }) => {
        console.log("rejected", error);
        state.createPassword.isLoading = false;
      });

    // CLIENT FORGOT PASSWORD
    builder
      .addCase(forgotPassword.fulfilled, (state, { payload }) => {
        console.log("fulfilled", payload);
        state.forgotPassword.isLoading = false;
        // notify("Password created successfully", { type: "success" });
        window.location.href = "/email_sent";
      })
      .addCase(forgotPassword.pending, (state, action) => {
        state.forgotPassword.isLoading = true;
      })
      .addCase(forgotPassword.rejected, (state, { error }) => {
        console.log("rejected", error);
        state.forgotPassword.isLoading = false;
      });

    // CLIENT RESET PASSWORD
    builder
      .addCase(resetPasswordApi.fulfilled, (state, { payload }) => {
        console.log("fulfilled", payload);
        state.resetPassword.isLoading = false;
        notify("Password created successfully", { type: "success" });
        window.location.href = "/";
      })
      .addCase(resetPasswordApi.pending, (state, action) => {
        state.resetPassword.isLoading = true;
      })
      .addCase(resetPasswordApi.rejected, (state, { error }) => {
        console.log("rejected", error);
        state.resetPassword.isLoading = false;
      });

    // GET ALL PERMISSIONS
    builder
      .addCase(getAllPermission.fulfilled, (state, { payload }) => {
        console.log("fulfilled", payload);
        state.permissions = payload;
      })
      .addCase(getAllPermission.pending, (state, action) => {
        console.log("pending");
      })
      .addCase(getAllPermission.rejected, (state, { error }) => {
        console.log("rejected", error);
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice;
