import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../configs/api";
// import authAxiosService from "../../services/authAxiosService";
import axiosServices from "../../services/axiosServices";
// import { setToken } from "../../utils/storage";
import { toast } from "react-toastify";
import { createQuery } from "../../utils/createQuery";
import { errorHandler } from "../../utils/actionsErrorHandler";

// export const getAllUsers = createAsyncThunk(
//   "auth/getAllUsers",
//   async (payload, thunkAPI) => {
//     try {
//       const res = await axiosServices.get(
//         `${BASE_URL}/v3/users?per_page=${payload?.per_page}&page=${payload?.page}&user_role=${payload?.user_role}&search_by_name=${payload?.search_by_name}`
//       );
//       console.log("getAllUsers", res);
//       const userData = res?.result?.data;
//       return userData;
//     } catch (error) {
//       toast.error(error?.response?.data?.message);
//       throw error;
//     }
//   }
// );

export const getAllUsers = createAsyncThunk(
  "auth/getAllUsers",
  async (payload, thunkAPI) => {
    try {
      const params = new URLSearchParams();
      
      if (payload?.per_page) params.append('per_page', payload.per_page);
      if (payload?.page) params.append('page', payload.page);
      if (payload?.user_role) params.append('user_role', payload.user_role);
      if (payload?.search_by_name) params.append('search_by_name', payload.search_by_name);

      const res = await axiosServices.get(`${BASE_URL}/v3/users?${params.toString()}`);
      console.log("getAllUsers", res);

      const userData = res?.result?.data;
      return userData;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      throw error;
    }
  }
);

export const addUser = createAsyncThunk(
  "auth/addUser",
  async (payload, thunkAPI) => {
    try {
      const response = await axiosServices.post(
        `${BASE_URL}/v3/users`,
        payload
      );
      // console.log(response);
      toast.success("User added successfully");
      window.location.href = "/crop-gate/user-management/users";
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      throw error;
    }
  }
);

export const getUserById = createAsyncThunk(
  "auth/getUserById",
  async (payload, thunkAPI) => {
    try {
      const response = await axiosServices.get(
        `${BASE_URL}/v3/users/${payload}`
      );
      console.log(response);
      return response?.result?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      throw error;
    }
  }
);

export const getFarmers = createAsyncThunk(
  "userManagement/getFarmers",
  async (queryParams = {}, thunkAPI) => {
    queryParams = createQuery(queryParams);
    try {
      const res = await axiosServices.get(`/v3/farmers${queryParams}`);
      return res?.result?.data;
    } catch (error) {
      console.log(error);
      errorHandler(error);
      throw error;
    }
  }
);

export const getTenants = createAsyncThunk(
  "userManagement/getTenants",
  async (queryParams = {}, thunkAPI) => {
    queryParams = createQuery(queryParams);
    try {
      const res = await axiosServices.get(`/v3/tenants${queryParams}`);
      return res?.result?.tenants;
    } catch (error) {
      console.log(error);
      errorHandler(error);
      throw error;
    }
  }
);

export const getMyTenants = createAsyncThunk(
  "userManagement/getMyTenants",
  async (queryParams = {}, thunkAPI) => {
    queryParams = createQuery(queryParams);
    try {
      const res = await axiosServices.get(`/v4/super-tenant/tenants${queryParams}`);
      return res?.result?.tenants;
    } catch (error) {
      console.log(error);
      errorHandler(error);
      throw error;
    }
  }
);

export const updateTenant = createAsyncThunk(
  "userManagement/updateUser",
  async (payload, thunkAPI) => {
    try {
      const res = await axiosServices.put(
        `/v3/tenants/${payload?.id}`,
        payload
      );
      return res?.result?.data;
    } catch (error) {
      errorHandler(error);
      throw error;
    }
  }
);

export const getAllServices = createAsyncThunk(
  "userManagement/getAllServices",
  async (queryParams = {}, thunkAPI) => {
    queryParams = createQuery(queryParams);
    try {
      const res = await axiosServices.get(`/v3/services${queryParams}`);
      return res?.result?.services;
    } catch (error) {
      console.log(error);
      errorHandler(error);
      throw error;
    }
  }
);

export const getSingleTenant = createAsyncThunk(
  "userManagement/getSingleTenant",
  async (payload, thunkAPI) => {
    try {
      const res = await axiosServices.get(`/v3/tenants/${payload}`);
      return res?.result?.tenant;
    } catch (error) {
      console.log(error);
      errorHandler(error);
      throw error;
    }
  }
);

export const getRoles = createAsyncThunk(
  "userManagement/getRoles",
  async (queryParams = {}, thunkAPI) => {
    queryParams = createQuery(queryParams);
    try {
      const res = await axiosServices.get(`/v3/roles${queryParams}`);
      return res?.result?.roles;
    } catch (error) {
      console.log(error);
      errorHandler(error);
      throw error;
    }
  }
);

export const getSourcingAgents = createAsyncThunk(
  "userManagement/getSourcingAgents",
  async (queryParams = {}, thunkAPI) => {
    queryParams = createQuery(queryParams);
    try {
      const res = await axiosServices.get(
        `/v3/sourcing-partners${queryParams}`
      );
      return res?.result?.sourcing_partners;
    } catch (error) {
      console.log(error);
      errorHandler(error);
      throw error;
    }
  }
);

export const AddSourcingAgents = createAsyncThunk(
  "userManagement/addSourcingAgent",
  async (payload, thunkAPI) => {
    try {
      const response = await axiosServices.post(
        `/v3/sourcing-partners`,
        payload
      );
      // console.log(response);
      return response;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

export const deleteTenant = createAsyncThunk(
  "userManagement/DeleteTenant",
  async (payload, thunkAPI) => {
    try {
      const response = await axiosServices.delete(
        `/v3/tenants/${payload}`,
        payload
      );
      // console.log(response);
      return response;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

export const deleteAgent = createAsyncThunk(
  "userManagement/DeleteAgent",
  async (payload, thunkAPI) => {
    try {
      const response = await axiosServices.delete(
        `/v3/sourcing-partners/${payload}`,
        payload
      );
      // console.log(response);
      return response;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

export const AddTenant = createAsyncThunk(
  "userManagement/AddTenant",
  async (payload, thunkAPI) => {
    try {
      const response = await axiosServices.post(`/v3/tenants`, payload);
      // console.log(response);
      return response;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);
