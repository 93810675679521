import { getBanks } from "../actions/utility.action";
import { createSlice } from "@reduxjs/toolkit";
// import { notify } from "../../utils/toastNotification";

const initialState = {
  banks: [],
  isLoading: false,
};

const utilitySlice = createSlice({
  name: "utility",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBanks.fulfilled, (state, { payload }) => {
        console.log("fulfilled");
        state.isLoading = false;
        state.banks = payload || [];
      })
      .addCase(getBanks.pending, (state, action) => {
        console.log("pending");
        state.isLoading = true;
      })
      .addCase(getBanks.rejected, (state, { error }) => {
        console.log("rejected");
        state.isLoading = false;
        // notify(error.message, { type: "error" });
      });
  },
});

export default utilitySlice;
