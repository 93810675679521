import { createSlice } from "@reduxjs/toolkit";
import {
  getActivityCategories,
  getCropCalendar,
  getCropProfiles,
  getFarmSeassons,
  getCategoriesByCrop,
  getDraftedCropProfiles
} from "../actions/Configurations.action";

const initialState = {
  cropProfiles: {
    data: [],
    total: 0,
    totalCategories: 0,
    totalTasks: 0,
    totalCropProfiles: 0,
    loading: false,
  },
  cropCalendar: {
    data: [],
    total: 0,
    loading: false,
  },
  farmSeasons: {
    data: [],
    total: 0,
    loading: false,
  },
  activityCategories: {
    data: [],
    total: 0,
    loading: false,
  },
  categories: {
    data: [],
    total: 0,
    loading: false,
  },
  drafts: {
    data: [],
    total: 0,
    loading: false,
  },
};

const configurationsSlice = createSlice({
  name: "configurations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCropProfiles.fulfilled, (state, { payload }) => {
        state.cropProfiles.data = payload?.crop_profiles?.data || [];
        state.cropProfiles.total = payload?.crop_profiles?.total || 0;
        state.cropProfiles.totalCategories =
          payload?.count_categories || 0;
        state.cropProfiles.totalTasks = payload?.count_tasks || 0;
        state.cropProfiles.totalCropProfiles =
          payload?.count_crop_profiles || 0;
        state.cropProfiles.loading = false;
      })
      .addCase(getCropProfiles.pending, (state) => {
        state.cropProfiles.loading = true;
      })
      .addCase(getCropProfiles.rejected, (state) => {
        state.cropProfiles.loading = false;
      })

      .addCase(getCropCalendar.fulfilled, (state, { payload }) => {
        // console.log("payload", payload)
        state.cropCalendar.data = payload?.data || [];
        state.cropCalendar.total = payload?.total || 0;
        state.cropCalendar.loading = false;
      })
      .addCase(getCropCalendar.pending, (state) => {
        state.cropCalendar.loading = true;
      })
      .addCase(getCropCalendar.rejected, (state) => {
        state.cropCalendar.loading = false;
      })

      .addCase(getFarmSeassons.fulfilled, (state, { payload }) => {
        // console.log("********", payload)
        state.farmSeasons.data = payload?.data || [];
        state.farmSeasons.total = payload?.total || 0;
        state.farmSeasons.loading = false;
      })
      .addCase(getFarmSeassons.pending, (state) => {
        state.farmSeasons.loading = true;
      })
      .addCase(getFarmSeassons.rejected, (state) => {
        state.farmSeasons.loading = false;
      })

      .addCase(getActivityCategories.fulfilled, (state, { payload }) => {
        console.log("payload")
        state.activityCategories.data = payload?.activityCategories?.data || [];
        state.activityCategories.total =
          payload?.activityCategories?.total || 0;
        state.activityCategories.loading = false;
      })
      .addCase(getActivityCategories.pending, (state) => {
        state.activityCategories.loading = true;
      })
      .addCase(getActivityCategories.rejected, (state) => {
        state.activityCategories.loading = false;
      })

    .addCase(getCategoriesByCrop.fulfilled, (state, { payload }) => {
      // console.log("gories", payload)
      state.categories.data = payload?.categories || [];
      state.categories.total = payload?.categories?.total || 0;
      state.categories.loading = false;
    })
    .addCase(getCategoriesByCrop.pending, (state) => {
      state.categories.loading = true;
    })
    .addCase(getCategoriesByCrop.rejected, (state) => {
      state.categories.loading = false;
    })

    .addCase(getDraftedCropProfiles.fulfilled, (state, { payload }) => {
      state.drafts.data = payload?.drafts || [];
      state.drafts.total = payload?.total || 0;
      state.drafts.loading = false;
    })
    .addCase(getDraftedCropProfiles.pending, (state) => {
      state.drafts.loading = true;
    })
    .addCase(getDraftedCropProfiles.rejected, (state) => {
      state.drafts.loading = false;
    })
  },
});

export default configurationsSlice;
